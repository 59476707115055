import React, {Component} from 'react';

import momentTimezone from 'moment-timezone';
import moment from 'moment';

import IconBadge from '../common/IconBadge';
import {hasReboqueMeProfile} from '../../utils/check_permission';
import {ServiceTypes, StatusTypes} from '../../config';
import userIcon from '../../assets/img/user-icon.png';

const fixDate = (date) => {
  const datePart = date.split(' ')[0];
  const timePart = date.split(' ')[1];
  const day = datePart.split('/')[0];
  const month = datePart.split('/')[1] - 1;
  const year = datePart.split('/')[2];
  const hour = timePart.split(':')[0];
  const minute = timePart.split(':')[1];
  const second = timePart.split(':')[2];
  return new Date(year, month, day, hour, minute, second);
};

const FollowUpItem = ({solicitation, onItemSelect, solicitationCode, login}) => {
  let toRender = <h1>Error</h1>;
  if (solicitation.statusSolicitacao === 'Agendada') {
    toRender = (
      <li
        className={
          solicitationCode && solicitation.codigo === solicitationCode.codigo
            ? `list-group-item solicitation-item solicitation-active`
            : `list-group-item solicitation-item`
        }
        onClick={onItemSelect}
        id={solicitation.codigo}
        style={{minHeight: `100px`}}>
        <IconBadge typeList={StatusTypes} type={solicitation.statusSolicitacao} />
        <i className="fa fa-user" />{' '}
        <strong>
          {solicitation.cliente.nome} {solicitation.cliente.sobreNome}
        </strong>
        <br />
        {hasReboqueMeProfile() && (
          <div>
            <i className="fas fa-building" style={{marginRight: 4}} />{' '}
            <strong>{solicitation.empresa.nomeFantasia}</strong>
            <br />
          </div>
        )}
        {solicitation.acionados &&
          solicitation.acionados.map(({parceiro: {contato, codigo}}) => (
            <div key={codigo}>
              <i className="fas fa-truck" style={{marginRight: 4}} />
              <strong>{contato}</strong>
            </div>
          ))}
        <br />
        {solicitation.veiculoCliente.marca} {solicitation.veiculoCliente.modelo}
        <small className="pull-right">Criada em {solicitation.dataCadastro}</small>
        <br />
        {typeof solicitation.veiculoCliente.placa !== 'undefined'
          ? solicitation.veiculoCliente.placa.toUpperCase()
          : null}
        <small className="pull-right">{`Agendamento ${solicitation.dataAgendada}`}</small>
        <br />
        <small className="pull-right">{`Previsão de Expiração ${solicitation.previsaoExpiracao}`}</small>
        <br />
      </li>
    );
  } else if (solicitation.statusSolicitacao === 'Em Atendimento') {
    let late = false;
    let caught = false;
    let customPriority = {};

    switch (solicitation.priority) {
      case 1:
        customPriority.backgroundColor = '#A99CEC';
        customPriority.description = 'Checklist Pendente';
        customPriority.icon = 'fas fa-list-ul';
        break;

      case 2:
        customPriority.backgroundColor = '#ffc000';
        customPriority.description = 'Atenção';
        customPriority.icon = 'far fa-eye';
        break;

      case 3:
        customPriority.backgroundColor = '#FF9999';
        customPriority.description = 'Possível Atraso';
        customPriority.icon = 'far fa-bell';
        break;

      case 4:
        customPriority.backgroundColor = '#71148C';
        customPriority.description = 'Checklist Pendente';
        customPriority.icon = 'fas fa-list-ul ';
        break;

      case 5:
        customPriority.backgroundColor = 'red';
        customPriority.description = 'Atrasado';
        customPriority.icon = 'fas fa-exclamation-triangle';
        break;
    }

    if (solicitation.dataChegadadoParceiro) {
      caught = true;
      if (
        moment(solicitation.dataChegadadoParceiro, 'DD/MM/YY HH:mm').diff(
          moment(solicitation.respostaAceita.dataCadastro, 'DD/MM/YY HH:mm'),
          'minutes',
        ) > 35
      ) {
        late = true;
      }
    } else {
      if (moment().diff(fixDate(solicitation.respostaAceita.dataCadastro), 'minutes') > 35) {
        late = true;
      }
    }
    const customType = {
      color: late ? 'warning' : 'primary',
      icon: late ? 'far fa-clock' : 'fas fa-thumbs-up',
      description: caught ? 'Chegou' : 'Não Chegou ',
    };
    toRender = (
      <li
        className={
          solicitationCode && solicitation.codigo === solicitationCode.codigo
            ? `list-group-item solicitation-item solicitation-active`
            : `list-group-item solicitation-item`
        }
        onClick={onItemSelect}
        id={solicitation.codigo}
        style={{minHeight: `100px`}}>
        <IconBadge
          customType={customType}
          typeList={StatusTypes}
          type={solicitation.statusSolicitacao}
        />
        {solicitation.priority ? (
          <span
            className="badge"
            style={{
              backgroundColor: customPriority.backgroundColor,
              color: '#ffffff',
              margin: '5px 0',
              clear: 'both',
            }}>
            <i className={customPriority.icon} /> {customPriority.description}
          </span>
        ) : (
          ''
        )}
        <i className={ServiceTypes[solicitation.tipoServico.codigo].icon} />{' '}
        <strong>
          {solicitation.cliente.nome} {solicitation.cliente.sobreNome}
        </strong>
        <br />
        {hasReboqueMeProfile(login) && solicitation.usuarioResponsavelMonitoramento ? (
          <span>
            <img
              src={solicitation.usuarioResponsavelMonitoramento.linkFotoPerfil || userIcon}
              alt={solicitation.usuarioResponsavelMonitoramento.nome}
              className="img-circle"
              style={{
                width: '20px',
                marginRight: '4px',
                marginTop: '7px',
                marginBottom: '7px',
              }}
            />
            <small>{solicitation.usuarioResponsavelMonitoramento.nome}</small>
          </span>
        ) : null}
        <br />
        {solicitation.veiculoCliente.marca} {solicitation.veiculoCliente.modelo} /{' '}
        {typeof solicitation.veiculoCliente.placa !== 'undefined'
          ? solicitation.veiculoCliente.placa.toUpperCase()
          : null}
        <br />
        {solicitation.respostaAceita.parceiro.nomeFantasia} <br />
        {solicitation.respostaAceita.parceiro.nomeFantasia !==
        solicitation.respostaAceita.parceiro.contato
          ? solicitation.respostaAceita.parceiro.contato
          : ''}
        <small className="pull-right">{solicitation.dataCadastro}</small>
      </li>
    );
  } else if (
    solicitation.statusSolicitacao === 'Aguardando Proposta' ||
    solicitation.statusSolicitacao === 'Expirada'
  ) {
    let icon;

    if (ServiceTypes[solicitation.tipoServico.codigo]) {
      icon = ServiceTypes[solicitation.tipoServico.codigo].icon;
    }

    toRender = (
      <li
        className={
          solicitationCode && solicitation.codigo === solicitationCode.codigo
            ? `list-group-item solicitation-item solicitation-active`
            : `list-group-item solicitation-item`
        }
        onClick={onItemSelect}
        id={solicitation.codigo}
        style={{minHeight: `100px`}}>
        <IconBadge typeList={StatusTypes} type={solicitation.statusSolicitacao} />
        {solicitation.dataAgendada && (
          <span
            className="badge"
            style={{
              backgroundColor: 'burlywood',
              color: '#ffffff',
              margin: '0 5px',
            }}>
            <i className={'badge'} /> {'Agendada'}
          </span>
        )}
        <i className={icon} />{' '}
        <strong>
          {solicitation.cliente.nome} {solicitation.cliente.sobreNome}
        </strong>
        <br />
        {hasReboqueMeProfile(login) &&
        solicitation.usuarioResponsavelAcionamento &&
        solicitation.usuarioResponsavelAcionamento.nome ? (
          <span>
            <img
              src={solicitation.usuarioResponsavelAcionamento.linkFotoPerfil || userIcon}
              alt={solicitation.usuarioResponsavelAcionamento.nome}
              className="img-circle"
              style={{
                width: '20px',
                marginRight: '4px',
                marginTop: '7px',
                marginBottom: '7px',
              }}
            />
            <small>{solicitation.usuarioResponsavelAcionamento.nome}</small>
          </span>
        ) : null}
        <br />
        {solicitation.veiculoCliente.marca} {solicitation.veiculoCliente.modelo}
        <small className="pull-right">{`Criada em ${solicitation.dataCadastro}`}</small>
        <br />
        {solicitation.veiculoCliente.placa
          ? solicitation.veiculoCliente.placa.toUpperCase()
          : null}
        <small className="pull-right">{`Expira em ${solicitation.previsaoExpiracao}`}</small>
      </li>
    );
  } else if (solicitation.statusSolicitacao === 'Interrompida') {
    toRender = (
      <li
        className={
          solicitationCode && solicitation.codigo === solicitationCode.codigo
            ? `list-group-item solicitation-item solicitation-active`
            : `list-group-item solicitation-item`
        }
        onClick={onItemSelect}
        id={solicitation.codigo}
        style={{minHeight: `100px`}}>
        <IconBadge typeList={StatusTypes} type={solicitation.statusSolicitacao} />
        <i className={ServiceTypes[solicitation.tipoServico.codigo].icon} />{' '}
        <strong>
          {solicitation.cliente.nome} {solicitation.cliente.sobreNome}
        </strong>
        <br />
        <br />
        {solicitation.veiculoCliente.marca} {solicitation.veiculoCliente.modelo}
        <small className="pull-right">{`Criada em ${solicitation.dataCadastro}`}</small>
        <br />
        {typeof solicitation.veiculoCliente.placa !== 'undefined'
          ? solicitation.veiculoCliente.placa.toUpperCase()
          : null}
        <small className="pull-right">{`Expira em ${solicitation.previsaoExpiracao}`}</small>
      </li>
    );
  }
  return toRender;
};

export default FollowUpItem;
