import React from "react";

import Card from "../common/Card";
import FollowUpItem from "./FollowUpItem";
import RenderList from "../common/RenderList";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  PENDING_SOLICITATIONS,
  IN_PROGRESS_SOLICITATIONS,
  SCHEDULED_SOLICITATIONS,
} from "../../config.js";

const FollowUptabs = ({
  inProgressSolicitations,
  pendingSolicitations,
  scheduledSolicitations,
  onItemSelect,
  onFilter,
  height,
  handleTab,
  tabSelected,
  solicitationCode,
  loadConcludedSolicitations,
  loadScheduledSolicitations,
  loadInProgressSolicitations,
  loadPendingSolicitations,
  login,
}) => {
  const _handleInProgressFilter = (event) => {
    onFilter(event, IN_PROGRESS_SOLICITATIONS);
  };

  const _handlePendingFilter = (event) => {
    onFilter(event, PENDING_SOLICITATIONS);
  };

  const _handleScheduledFilter = (event) => {
    onFilter(event, SCHEDULED_SOLICITATIONS);
  };

  const handleSelect = (index, last) => {
    //index === 2 ? loadConcludedSolicitations() : null
    handleTab(index);
  };
  const handleRefreshScheduled = () => {
    loadScheduledSolicitations();
  };
  const handleRefreshInProgress = () => {
    loadInProgressSolicitations();
  };
  const handleRefreshPending = () => {
    loadPendingSolicitations();
  };

  const renderListItem = (solicitation) => {
    return (
      <FollowUpItem
        key={solicitation.codigo}
        solicitation={solicitation}
        onItemSelect={onItemSelect}
        solicitationCode={solicitationCode}
        login={login}
      />
    );
  };

  return (
    <div className="full-height-scroll">
      <Tabs
        selectedIndex={tabSelected}
        onSelect={handleSelect}
        className="tabs-container"
      >
        <TabList className="nav nav-tabs">
          <Tab>Agendadas</Tab>
          <Tab>Pendentes</Tab>
          <Tab>Em Atendimento</Tab>
        </TabList>
        <TabPanel>
          <RenderList
            msgEmpty="Não há nenhuma solicitação agendada no momento"
            renderListItem={(solicitation) => renderListItem(solicitation)}
            height={height}
            onFilter={_handleScheduledFilter}
            list={scheduledSolicitations}
            onRefresh={handleRefreshScheduled}
          />
        </TabPanel>
        <TabPanel>
          <RenderList
            msgEmpty="Não há nenhuma solicitação pendente no momento"
            renderListItem={renderListItem}
            height={height}
            onFilter={_handlePendingFilter}
            // applyLoginFilter={this.applyLoginFilter}
            // removeLoginFilter={this.removeLoginFilter}
            // isLoginFiltered={loginFiltered}
            list={pendingSolicitations}
            onRefresh={handleRefreshPending}
          />
        </TabPanel>
        <TabPanel>
          <RenderList
            msgEmpty="Não há nenhuma solicitação em andamento no momento"
            renderListItem={renderListItem}
            height={height}
            onFilter={_handleInProgressFilter}
            list={inProgressSolicitations}
            onRefresh={handleRefreshInProgress}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default FollowUptabs;
