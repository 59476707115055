import React from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {connect} from 'react-redux';
import _ from 'lodash';
import swal from 'sweetalert2';
import TitleButton from '../../common/titleButtons/TitleButton';
import CustonTitleButton from '../../common/titleButtons/CustonTitleButton';
import {USUARIO_TIPO_EMPRESA, USUARIO_TIPO_REBOQUEME} from '../../../reducers/constants';
import {browserHistory} from 'react-router';
import {toastError, toastSuccess} from '../../../actions/toast_actions';
import ReactTooltip from 'react-tooltip';
import './styles.css';
import CustomModal, {
  doOpenModal,
  doCloseModal,
  CustomModalWithoutTabIndex,
} from '../../common/CustomModal';
import {updateObservationSolicitation} from '../dashboardActions';
export const NO_PATIO = 'VEICULONOPATIO';
export const A_CAMINHO = 'VEICULOACAMINHODOPATIO';
export const SAINDO_PATIO = 'VEICULOSAINDODOPATIO';
export const PRONTO_PRA_SAIR = 'VEICULOPRONTOPARASAIR';
import {bindActionCreators} from 'redux';
import {canAccessEditObservation, hasActivationAccess} from '../../../utils/check_permission';
import AddressAutoComplete from '../../containers/AddressAutoComplete';
import {doGet} from '../../../api/base/base_api_helper';
import AsyncSelect from 'react-select/lib/Async';
import Toggle from 'react-toggle-button';
import {
  onFieldChange,
  getPriceAndTimePredictionModal,
  getPriceAndTimePredictionFormModal,
  sendSolicitationModal,
  verifyActivations,
  callPartners,
} from '../../../actions/service_actions';
import {onAddressChange} from '../../../actions/address_actions';
import {onInputCleared, onOptionSelected} from '../../../actions/autocomplete_actions';
import {useHistory} from 'react-router-dom';
import {push} from 'react-router-redux';
class ParkingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      solicitationObservation: '',
      currentSolicitationCode: null,
      options: [],
      value: '',
      isNewSolicitationModalOpen: false,
      callPartner: false,
      step: 1,
      enderecoDestino: '',
      blindado: false,
      capotado: false,
      semRodas: false,
      semChaves: false,
      quantidadeRodasTravadas: 0,
    };
    this.toggleStartCallPartner = this.toggleStartCallPartner.bind(this);
    this.updateObservation = this.updateObservation.bind(this);
    this.formatChecklist = this.formatChecklist.bind(this);
  }

  setTableLoader = () => {
    if (this.props.isFetching === false) {
      return `Nenhum registro encontrado`;
    } else {
      return (
        <div className="spiner-example">
          <div className="sk-spinner sk-spinner-double-bounce">
            <div className="sk-double-bounce1" />
            <div className="sk-double-bounce2" />
          </div>
        </div>
      );
    }
  };

  onCheckChange = (name, value) => {
    this.props.onFieldChange(name, value);
  };
  toggleStartCallPartner = (value) => {
    this.setState({
      callPartner: !value,
    });
    this.onCheckChange('callPartner', !value); // Não vai mais gerar o erro
  };

  truncateAddress = (address, maxLen) => {
    return address && address.length > maxLen ? address.substring(0, maxLen) + '...' : address;
  };
  formatStatus = (row, cel) => {
    let title = cel.status ? cel.status.descricao : '';
    let color = '';

    if (cel.status.codigo === A_CAMINHO) {
      color = '#f8ac59';
    } else if (cel.status.codigo === NO_PATIO) {
      color = '#5c40ff';
    } else if (cel.status.codigo === PRONTO_PRA_SAIR) {
      color = '#25f4af';
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <span
          className={`badge-adapted `}
          style={{
            backgroundColor: color,
            color: color === '#5c40ff' || color === '#25f4af' ? 'white' : 'black',
            whiteSpace: 'nowrap',
            padding: '4px',
          }}>
          {title}
        </span>
      </div>
    );
  };

  formatChecklist(cell, row) {
    const situacaoVeiculo = row.situacaoVeiculo;

    const handleButtonClick = () => {
      if (situacaoVeiculo) {
        this.setState(
          {
            blindado: situacaoVeiculo.blindado,
            capotado: situacaoVeiculo.capotado,
            semRodas: situacaoVeiculo.semRodas,
            semChaves: situacaoVeiculo.semChaves,
            quantidadeRodasTravadas: situacaoVeiculo.quantidadeRodasTravadas,
          },
          () => {
            doOpenModal('CONTEXT_CHECKLIST');
          },
        );
      }
    };
    if (
      row.status.codigo === 'VEICULOPRONTOPARASAIR' ||
      row.status.codigo === 'VEICULONOPATIO'
    ) {
      return (
        <button
          className="btn btn-sm btn-default"
          style={
            row.status.codigo === 'VEICULOPRONTOPARASAIR'
              ? {color: '#4ab858', textAlign: 'center'}
              : {color: '#c1c1c1', textAlign: 'center'}
          }
          onClick={handleButtonClick}>
          <i className="fas fa-check" />
        </button>
      );
    }
    return <span />;
  }

  formatEmpresaName = (row, cel) => {
    if (
      cel.solicitacaoEntrada &&
      cel.solicitacaoEntrada.empresa &&
      cel.solicitacaoEntrada.empresa.nomeFantasia
    ) {
      const title = cel.solicitacaoEntrada.empresa.nomeFantasia;
      return <span>{title}</span>;
    }
    return <span>-</span>;
  };

  formatPatioName = (row, cel) => {
    if (cel.patio && cel.patio.endereco && cel.patio.endereco.logradouro) {
      const patioName = cel.patio.endereco.logradouro;
      return (
        <div>
          <span data-tip data-for={`${patioName}-info`} className="patio-container">
            {patioName}
          </span>
          <ReactTooltip
            id={`${patioName}-info`}
            type="info"
            effect="solid"
            className="tooltip-patio">
            <span>{patioName}</span>
          </ReactTooltip>
        </div>
      );
    }
    return <span>-</span>;
  };

  formatVeiculo = (row, cel) => {
    if (cel.solicitacaoEntrada && cel.solicitacaoEntrada.veiculoCliente) {
      let veiculoInfo = '';
      if (cel.solicitacaoEntrada.veiculoCliente.marca) {
        veiculoInfo += cel.solicitacaoEntrada.veiculoCliente.marca;
      }
      if (cel.solicitacaoEntrada.veiculoCliente.modelo) {
        veiculoInfo += ' ' + cel.solicitacaoEntrada.veiculoCliente.modelo;
      }
      return (
        <div>
          <span data-tip data-for={`${veiculoInfo}-info`}>
            {veiculoInfo}
          </span>
          <ReactTooltip
            id={`${veiculoInfo}-info`}
            type="info"
            effect="solid"
            className="tooltip-patio">
            <span>{veiculoInfo}</span>
          </ReactTooltip>
        </div>
      );
    }
    return <span>-</span>;
  };

  formatObservation = (row, cel) => {
    if (cel.observacao) {
      const observation = cel.observacao.replace(/\n/g, ' '); // Substitui todas as quebras de linha por um espaço
      return (
        <div>
          <span data-tip data-for={`${observation}-info`}>
            {observation}
          </span>
          <ReactTooltip
            id={`${observation}-info`}
            type="info"
            effect="solid"
            className="tooltip-patio">
            <span>{observation}</span>
          </ReactTooltip>
        </div>
      );
    }
    return <span>-</span>;
  };

  formatPernoite = (row, cel) => {
    if (cel.enderecoDestinoSolicitado) {
      const endereco = cel.enderecoDestinoSolicitado.logradouro;
      return (
        <div>
          <span data-tip data-for={`${endereco}-info`}>
            {endereco}
          </span>
          <ReactTooltip
            id={`${endereco}-info`}
            type="info"
            effect="solid"
            className="tooltip-patio">
            <span>{endereco}</span>
          </ReactTooltip>
        </div>
      );
    }
    return <span>-</span>;
  };

  handleSolicitationButton = (veiculoData) => {
    if (!veiculoData.solicitacaoEntrada) {
      toastError('Veículo não possui solicitação de entrada');
      return;
    }
    const {solicitacaoEntrada} = veiculoData;
    if (veiculoData.status.codigo === NO_PATIO) {
      const {
        veiculoCliente: {placa},
      } = solicitacaoEntrada;
      window.scrollTo(0, 0);
      browserHistory.push({
        pathname: `/solicitacoes/relatorios`,
        state: {
          solicitationCode: solicitacaoEntrada.codigo,
          placa,
        },
      });
    } else {
      window.scrollTo(0, 0);
      browserHistory.push({
        pathname: `/followup`,
        state: {
          showEmAndamento: true,
        },
      });
    }
  };

  updateObservation = (value) => {
    this.setState({
      solicitationObservation: value,
    });
  };

  showSuccessAlert = () => {
    toastSuccess('Atualizado com sucesso!');
  };
  sendNewObservation = () => {
    const {currentSolicitationCode, solicitationObservation, enderecoDestino} = this.state;
    this.props.onAddressChange('enderecoDestino', enderecoDestino, true);

    const requestedDestinyAddress = {
      place: this.props.logradouro,
      complement: this.props.complemento,
      district: this.props.bairro,
      city: this.props.cidade,
      streetNumber: this.props.numero,
      zipCode: this.props.cep,
      country: this.props.pais,
      state: this.props.estado,
      longitude: this.props.longDest,
      latitude: this.props.latDest,
    };
    this.props.updateObservationSolicitation(
      currentSolicitationCode,
      solicitationObservation,
      requestedDestinyAddress,
    );
    this.setState({
      enderecoDestino: '',
      solicitationObservation: '',
      currentSolicitationCode: null,
    });
    this.nextStep();
    doCloseModal('CONTEXT_PATIO');
  };
  onChange = (value) => {
    this.setState({
      value: value,
    });
  };

  getClientNameOptionsDebounced = _.debounce((input, resolve, reject) => {
    const {options} = this.state;
    if (!input) {
      return Promise.resolve(options);
    }
    return doGet(`partners?_filter=${input}&&_status=PARCEIROATIVO,PARCEIROEMDESCANSO`)
      .then((res) => {
        if (res.sucesso === true) {
          if (res.listPartners) {
            return res.listPartners.map((partner) => {
              const formatNotEqualZero = () => {
                let output = '';

                if (partner.veiculo.patins !== 0) {
                  output += `| ${partner.veiculo.patins} Patin(s)`;
                }

                if (
                  partner.veiculo.tamanhoPlataforma &&
                  partner.veiculo.tamanhoPlataforma !== 0
                ) {
                  const {tamanhoPlataforma} = partner.veiculo;
                  output += `| ${tamanhoPlataforma.toString().replace('.', ',')} Metro(s)`;
                }

                return output;
              };

              return {
                value: partner,
                label: `${partner.contato} - ${partner.nomeFantasia} ${
                  partner.veiculo.asaDelta === 1 ? '| Com ASA' : ''
                } ${formatNotEqualZero()}`,
              };
            });
          }
        }
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  }, 300);

  getClientNameOptions = (input) => {
    return new Promise((resolve, reject) => {
      this.getClientNameOptionsDebounced(input, resolve, reject);
    });
  };

  nextStep = () => {
    this.setState({step: this.state.step + 1});
  };
  handleSubmit = (request_info) => {
    const {selectedCel} = this.state;
    if (!selectedCel.enderecoDestinoSolicitado) {
      return toastError('Insira um destino');
    }
    const enderecoDestino = {
      bairro: selectedCel.enderecoDestinoSolicitado.bairro,
      cep: selectedCel.enderecoDestinoSolicitado.cep,
      cidade: selectedCel.enderecoDestinoSolicitado.cidade,
      estado: selectedCel.enderecoDestinoSolicitado.estado,
      latitude: selectedCel.enderecoDestinoSolicitado.latitude,
      logradouro: selectedCel.enderecoDestinoSolicitado.logradouro,
      longitude: selectedCel.enderecoDestinoSolicitado.longitude,
      numero: selectedCel.enderecoDestinoSolicitado.numero,
      pais: selectedCel.enderecoDestinoSolicitado.pais,
    };
    request_info.solicitacaoEntrada.enderecoOrigem =
      request_info.solicitacaoEntrada.enderecoDestino;
    request_info.solicitacaoEntrada.enderecoDestino = enderecoDestino;
    request_info.solicitacaoEntrada.situacaoVeiculo = request_info.situacaoVeiculo;
    const auth = {
      password: null,
      username: null,
    };
    const sliderValue = this.state.sliderValue;
    this.nextStep();

    getPriceAndTimePredictionModal(request_info, auth, sliderValue)
      .then((data) => {
        this.setState({
          price: data.price,
          time: data.time,
          cupomValido: data.cupomValido,
          originalPrice: data.originalPrice,
        });
      })
      .catch((error) => {
        console.log('Erro:', error);
      });
  };
  closeModal = () => {
    this.setState({isNewSolicitationModalOpen: false});
  };
  pressSolicitation = async (event, callPartner, selectedPartner) => {
    let price = this.state.originalPrice;
    event.solicitacaoEntrada.valorCorrida = price;
    event.solicitacaoEntrada.situacaoVeiculo = event.situacaoVeiculo;
    event.solicitacaoEntrada.callPartner = callPartner;
    if (event.solicitacaoEntrada.atendimento) {
      event.codigoAtendimento = event.solicitacaoEntrada.atendimento.codigo;
    }
    const auth = {
      password: null,
      username: null,
    };
    let partnerCode = selectedPartner ? selectedPartner.value.codigo : null;

    sendSolicitationModal(event, auth)
      .then((result) => {
        doCloseModal('CONTEXT_NOVA_SOLICITACAO');
        toastSuccess('Solicitação criada com sucesso!');
        this.props.getParkingVehicles();
        if (partnerCode) {
          callPartners(partnerCode, result.solicitacao.codigo, true)
            .then((resp) => {
              toastSuccess('Parceiro acionado com sucesso!');
            })
            .catch((error) => {
              toastError(error.message);
            });
        }
      })
      .catch((error) => {
        toastError(error.message);
      });
  };
  linkButton = (row, cel) => {
    const user_info = JSON.parse(localStorage.getItem('user_info'));
    const isSoon = user_info.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME;
    const isEmpresa = user_info.usuario.tipoUsuario === USUARIO_TIPO_EMPRESA;

    return (
      <div>
        {(isSoon || isEmpresa) && cel.solicitacaoEntrada && (
          <TitleButton
            label="Detalhes"
            className="btn btn-sm btn-default"
            onClick={() => this.handleSolicitationButton(cel)}
          />
        )}

        {/* <div
          style={{
            marginTop: '5px',
            marginBottom: '5px',
          }}>
          <CustomModal title="Editar" context={'CONTEXT_PATIO'}>
            <div className="col-md-12">
              {canAccessEditObservation() && (
                <div>
                  <label>Observação</label>
                  <input
                    className="form-control observation-input"
                    value={this.state.solicitationObservation}
                    type="text"
                    onChange={(e) => this.updateObservation(e.target.value)}
                    id="accountNumber"
                  />
                </div>
              )}
              <div style={{flexGrow: 1, marginTop: '10px'}}>
                <label>Destino</label>
                <AddressAutoComplete
                  initialValue={
                    this.state.enderecoDestino ? this.state.enderecoDestino.logradouro : ''
                  }
                  favoriteDestinations
                  placeholder="Endereço de Destino"
                  id="enderecoDestino"
                  onOptionSelected={(endereco) => this.setState({enderecoDestino: endereco})}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexGrow: 1,
                    marginTop: '10px',
                  }}>
                  <button
                    className="btn btn-block btn-primary observation-button"
                    onClick={() => this.sendNewObservation()}>
                    Alterar {'  '}
                    <i className="fa fa-paper-plane observation-icon" />
                  </button>
                </div>
              </div>
            </div>
          </CustomModal>
          <TitleButton
            label="Editar"
            className="btn btn-sm btn-default"
            onClick={() => {
              this.setState({
                enderecoDestino: cel.enderecoDestinoSolicitado,
                step: 1,
                solicitationObservation: cel.observacao,
                currentSolicitationCode: cel.codigo,
              });
              doOpenModal('CONTEXT_PATIO');
            }}
          />
        </div> */}

        <div
          style={{
            marginTop: '5px',
          }}>
          {hasActivationAccess() && isSoon && cel.status.codigo === 'VEICULOPRONTOPARASAIR' && (
            <CustonTitleButton
              label="Saída"
              className="btn-sm btn-primary"
              onClick={() => {
                this.setState(
                  {
                    isNewSolicitationModalOpen: true,
                    selectedCel: cel,
                    step: 1,
                    value: '',
                    price: null,
                    time: null,
                    originalPrice: null,
                    enderecoDestino: '',
                    callPartner: false,
                  },
                  () => {
                    doOpenModal('CONTEXT_NOVA_SOLICITACAO');
                    this.handleSubmit(this.state.selectedCel);
                  },
                );
              }}
            />
          )}
        </div>

        {this.state.isNewSolicitationModalOpen && (
          <CustomModal title="Nova Solicitação" context={'CONTEXT_NOVA_SOLICITACAO'}>
            <div className="col-md-12">
              <div>
                <label>Valor atual</label>
                <p style={{fontSize: '25px'}}> R${this.state.originalPrice}</p>
                <div className="form-group">
                  <label className="form-label">Parceiro</label>
                  <AsyncSelect
                    loadOptions={this.getClientNameOptions}
                    onChange={this.onChange}
                    value={this.state.value}
                    defaultOptions
                    isClearable
                  />
                </div>

                <div className="form-group toggle-group">
                  <label style={{marginBottom: '14px'}}>Acionamento Automático</label>
                  <Toggle
                    inactiveLabel={'Não'}
                    activeLabel={'Sim'}
                    colors={{
                      activeThumb: {base: `#FFF`},
                      active: {base: `#5c40ff`},
                      inactiveThumb: {base: `#FFF`},
                      inactive: {base: `#FFF`},
                    }}
                    inactiveLabelStyle={{color: `#2f4050`}}
                    value={this.state.callPartner}
                    thumbStyle={{
                      borderRadius: 2,
                      borderColor: `#5c40ff`,
                    }}
                    trackStyle={{borderRadius: 2}}
                    onToggle={this.toggleStartCallPartner}
                  />
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-block btn-primary"
                    type="submit"
                    onClick={() =>
                      this.pressSolicitation(
                        this.state.selectedCel,
                        this.state.callPartner,
                        this.state.value,
                      )
                    }>
                    Criar Solitação
                  </button>
                </div>
              </div>
            </div>
          </CustomModal>
        )}
      </div>
    );
  };

  render() {
    const options = {
      noDataText: this.setTableLoader(),
      sizePerPage: 10,
    };

    const user_info = JSON.parse(localStorage.getItem('user_info'));
    const isSoon = user_info.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME;
    const isEmpresa = user_info.usuario.tipoUsuario === USUARIO_TIPO_EMPRESA;

    return (
      <div>
        <BootstrapTable
          options={options}
          data={this.props.parkingVehicles}
          condensed
          striped
          pagination
          bordered={false}
          hover>
          <TableHeaderColumn width="80" isKey dataField="placa">
            Placa
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.formatVeiculo} width="150">
            Veiculo
          </TableHeaderColumn>
          <TableHeaderColumn width="115" dataFormat={this.formatStatus}>
            Status
          </TableHeaderColumn>
          <TableHeaderColumn dataField="dataEntrada" width="160">
            Entrada
          </TableHeaderColumn>
          {isSoon && (
            <TableHeaderColumn dataFormat={this.formatEmpresaName} width="150">
              Empresa
            </TableHeaderColumn>
          )}
          {(isSoon || isEmpresa) && (
            <TableHeaderColumn dataFormat={this.formatPatioName} width="200">
              Patio
            </TableHeaderColumn>
          )}
          <TableHeaderColumn width="100" dataFormat={this.formatChecklist}>
            Vistoria
          </TableHeaderColumn>
          <TableHeaderColumn width="50" />
          <TableHeaderColumn dataFormat={this.formatObservation}>Observação</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.formatPernoite}>
            Destino Solicitado
          </TableHeaderColumn>

          <TableHeaderColumn dataFormat={this.linkButton} dataAlign="center" width="120">
            Ações
          </TableHeaderColumn>
        </BootstrapTable>
        <CustomModal title="Situação do veiculo" context={'CONTEXT_CHECKLIST'}>
          <div style={{marginBottom: '10px'}}>
            <h5
              style={{
                textAlign: 'center',
                border: '1px solid',
                padding: '5px',
                color: '#fff',
                backgroundColor: '#5c40ff',
                borderRadius: '5px',
              }}>
              Situação do Veiculo
            </h5>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              {this.state.blindado !== null && this.state.blindado !== undefined && (
                <div>
                  <h5>Blindado </h5>
                  <h5 style={{textAlign: 'center'}}>{this.state.blindado ? 'Sim' : 'Não'}</h5>
                </div>
              )}

              {this.state.semRodas !== null && this.state.semRodas !== undefined && (
                <div>
                  <h5>Sem Rodas</h5>
                  <h5 style={{textAlign: 'center'}}>{this.state.semRodas ? 'Sim' : 'Não'}</h5>
                </div>
              )}

              <div>
                <h5>Sem Chave</h5>
                <h5 style={{textAlign: 'center'}}>{this.state.semChaves ? 'Sim' : 'Não'}</h5>
              </div>

              <div>
                <h5>Rodas Travadas</h5>
                <h5 style={{textAlign: 'center'}}>{this.state.quantidadeRodasTravadas}</h5>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.dashboard.parkingVehiclesFetching,
  parkingVehicles: state.dashboard.parkingVehicles,
  logradouro: state.service.enderecoDestino.logradouro,
  latDest: state.service.enderecoDestino.latitude,
  longDest: state.service.enderecoDestino.longitude,
  bairro: state.service.enderecoDestino.bairro,
  cep: state.service.enderecoDestino.cep,
  cidade: state.service.enderecoDestino.cidade,
  estado: state.service.enderecoDestino.estado,
  numero: state.service.enderecoDestino.numero,
  pais: state.service.enderecoDestino.pais,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateObservationSolicitation,
      onFieldChange,
      onAddressChange,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParkingTable);
