import React, {Component} from 'react';
import {connect} from 'react-redux';
import swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(swal);
import {bindActionCreators} from 'redux';
import {
  getInvoices,
  setInvoiceBilletLink,
  sendEmails,
  setInvoiceNfLink,
  setInvoiceNfLinkAdapter,
  sendIndividualEmail,
  closeInvoice,
  billetButtonActions,
  sendNewBillet,
  generateAllBillets,
  InvoiceConstants,
  dismissNotaFiscal,
  clearInvoices,
  clearBalanceResume,
} from './balanceActions';
import ReactTable from './reactTableBalances';
import {TableHeaderColumn} from 'react-bootstrap-table';
import moment from 'moment';
import InvoiceCard from './invoiceCard';
import {Link} from 'react-router';
import './balance.css';
import CustomModal, {CustomModalWithoutTabIndex, doOpenModal} from '../common/CustomModal';
import DateTime from 'react-datetime';
import {doGet, doPut} from '../../api/base/base_api_helper';
import MaskedInput from '../common/MaskedInput';
import ReactTooltip from 'react-tooltip';

import {buildInvoiceNF} from '../../api/invoices_helper';
import SideDropDown, {SideDropDownAdapter, SideDropDownSend} from './sideDropDown';
import {toastSuccess, toastError} from '../../actions/toast_actions';
import {openWhatsApp} from '../../actions/followup_actions';
import {debounce} from 'lodash';

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 10,
      recordCount: 0,
      invoices: [],
      isFetching: true,
      afterAction: false,
      afterActionApproved: false,
      filteredInvoices: [],
      closeDate: new Date(),
      billetLink: [],
      invoiceLink: [],
      desconto: 9.7,
      acrescimo: 0,
      copyText: '',
      tipoNF: null,
    };

    this.getData = this.getData.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.addLinkButton = this.addLinkButton.bind(this);
    this.getBilletPartners = this.getBilletPartners.bind(this);
    this.getInvoicePartners = this.getInvoicePartners.bind(this);
    this.seenField = this.seenField.bind(this);
    this.getModalBody = this.getModalBody.bind(this);
    this.getClosesModal = this.getClosesModal.bind(this);
    this.doneButton = this.doneButton.bind(this);
    this.handleCloseDate = this.handleCloseDate.bind(this);
    this.handleBilletInput = this.handleBilletInput.bind(this);
    this.handleInvoiceInput = this.handleInvoiceInput.bind(this);
    this.billetButton = this.billetButton.bind(this);
    this.handleDesapprove = this.handleDesapprove.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.setCopyText = this.setCopyText.bind(this);
    this.doCopyText = this.doCopyText.bind(this);
    this.copyTotal = this.copyTotal.bind(this);
    this.notaFiscalPrestadorButton = this.notaFiscalPrestadorButton.bind(this);
  }

  componentWillUnmount() {
    this.props.clearInvoices();
    this.props.clearBalanceResume();
  }

  componentWillMount() {
    const {location, getInvoices} = this.props;
    const parts = location.pathname.split('/');
    this.descricao = location.state;

    this.id = parts[2];
    this.setState({
      balanceId: parts[2],
    });
    getInvoices(this.id);
    this.pdfButton = this.pdfButton.bind(this);
  }

  searchButton(cell, row) {
    return (
      <button className={`btn btn-sm btn-default`} onClick={() => this.search(cell, row)}>
        <i className="fa fa-search" />
      </button>
    );
  }

  billetButton(cell, row) {
    const {billetButtonActions, sendNewBillet} = this.props;
    const {balanceId} = this.state;
    /*     const billetButtonList = [
      {
        label: "Visualisar",
        func: () => window.open(row.billetLink, "blank"),
        icon: "fas fa-eye"
      },
      {
        label: "Segunda Via",
        func: () => billetButtonActions(balanceId, row.code, row.billetLink),
        icon: "fas fa-retweet"
      },
      {
        label: "Reenviar",
        func: () => sendNewBillet(row.lancamentoId),
        icon: "far fa-share-square"
      }
    ]; */
    const last = true;

    return row.billetLink ? (
      <div className={`btn-group show ${open}`}>
        {/*  <ReactTooltip /> */}
        <button
          data-toggle={`dropdown`}
          className={`btn btn-default dropdown-toggle hover-dropdown`}
          aria-expanded="false">
          <i className="fas fa-barcode" />
        </button>
        <ul
          className="dropdown-menu sideDropDown"
          style={{
            position: 'absolute',
            top: `${last ? '-5px' : '33px'}`,
            left: '-60px',
            ' willChange': 'top, left',
          }}>
          <li className="dropDown-li" title="Visualisar">
            <a
              className="dropdown-item balance-dropDown-a"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                window.open(row.billetLink, 'blank');
              }}>
              <i className="fas fa-eye" />
            </a>
          </li>

          <li className="dropDown-li" title="Segunda Via">
            <a
              className="dropdown-item balance-dropDown-a"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                billetButtonActions(balanceId, row.code, row.billetLink);
              }}>
              <i className="fas fa-retweet" />
            </a>
          </li>

          <li className="dropDown-li" title="Reenviar">
            <a
              className="dropdown-item balance-dropDown-a"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                sendNewBillet(row.lancamentoId);
              }}>
              <i className="far fa-share-square" />
            </a>
          </li>
        </ul>
      </div>
    ) : null;
  }

  async handleApprove(codigo) {
    if (codigo) {
      await doPut(`invoices/${codigo}/aprovarNf`, {
        approved: true,
      })
        .then(() => {
          this.setState({filteredInvoices: []});
          toastSuccess('Aprovado com sucesso');
          this.setState({afterAction: true, afterActionApproved: true});
          this.props.getInvoices(this.id);
        })
        .catch((error) => {
          toastError(error.mensagemCliente || error.status);
        });
    }
  }

  handleDesapprove(codigo) {
    MySwal.fire({
      title: 'Você tem certeza?',
      text: 'Deseja reprovar a nota fiscal ?',
      input: 'textarea',
      inputPlaceholder: 'Descreva o motivo da rejeição',
      icon: 'warning',
      width: '80',
      showCancelButton: true,
      confirmButtonText: 'Reprovar',
      confirmButtonColor: '#25f4af',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      preConfirm: (item) => {
        if (!item) {
          MySwal.showValidationMessage(`Por favor preencha o motivo da rejeição.`);
        }
      },
      inputAttributes: {
        style: 'font-size: 14px;',
      },
    }).then((result) => {
      if (result.value) {
        doPut(`invoices/${codigo}/aprovarNf`, {
          approved: false,
          motivoRecusa: result.value,
        })
          .then(() => {
            toastSuccess('Reprovado com sucesso');
            this.setState({afterAction: true});
            this.props.getInvoices(this.id);
          })
          .catch((error) => {
            toastError(error.mensagemCliente || error.status);
          });
      }
    });
  }

  recusarNota = (cell, row) => {
    const {dismissNotaFiscal} = this.props;
    const {balanceId} = this.state;

    dismissNotaFiscal(balanceId, row.code);
  };

  notaFiscalPrestadorButton(cell, row) {
    /* const { invoices } = this.state;
    const buttonList = [
      {
        label: "Visualisar",
        func: () => window.open(row.linkNotaFiscalTomada, "blank"),
        icon: "fas fa-eye"
      },
       {
        label: "Recusar",
        func: () => this.recusarNota(null, row),
        icon: "fas fa-times"
      },
    ];
    const last = invoices[invoices.length - 1].code === row.code;

    */
    const last = true;
    return row.linkNotaFiscalTomada ? (
      <div
        data-for="nf-data-recebimento"
        data-tip={`${row.dataRecebimentoNotaFiscalTomada || ''}${
          row.usuarioNotaFiscalAprovada
            ? ` - Aprovado por: ${row.usuarioNotaFiscalAprovada.nome}`
            : ''
        }`}
        className={`btn-group show ${open}`}>
        <button
          data-toggle={`dropdown`}
          className={`btn btn-default dropdown-toggle hover-dropdown`}
          aria-expanded="false">
          <i
            style={row.notaFiscalAprovada ? {color: '#4ab858'} : null}
            className="fas fa-file-invoice-dollar"
          />
        </button>
        <ReactTooltip id="nf-data-recebimento" place="top" effect="solid" />

        <ul
          className="dropdown-menu sideDropDown"
          style={{
            position: 'absolute',
            top: `${last ? '-5px' : '33px'}`,
            left: '-60px',
            ' willChange': 'top, left',
          }}>
          <li className="dropDown-li" title="Visualizar">
            <a
              className="dropdown-item balance-dropDown-a"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                window.open(row.linkNotaFiscalTomada, 'blank');
              }}>
              <i className="fas fa-eye" />
            </a>
          </li>

          <li className="dropDown-li" title="Recusar">
            <a
              className="dropdown-item balance-dropDown-a"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.recusarNota(null, row);
              }}>
              <i className="fas fa-times" />
            </a>
          </li>
        </ul>
      </div>
    ) : null;
  }

  invoiceButton(cell, row) {
    const tooltipContent =
      row.usuarioNotaFiscalAprovada && row.usuarioNotaFiscalAprovada.nome
        ? `Aprovada por: ${row.usuarioNotaFiscalAprovada.nome}`
        : '';

    return row.nfLink ? (
      <div>
        <button
          className={`btn btn-sm btn-default`}
          data-tip={tooltipContent}
          data-for={tooltipContent ? 'invoice-tooltip' : undefined}
          onClick={() => window.open(row.nfLink)}>
          <i className="fas fa-file-invoice-dollar" />
        </button>
        {tooltipContent && <ReactTooltip id="invoice-tooltip" place="top" effect="solid" />}
      </div>
    ) : null;
  }
  pdfButton(cell, row) {
    const balanceId = this.state.balanceId;
    return (
      <button
        className={`btn btn-sm btn-default`}
        onClick={() =>
          window.open(
            `https://web.reboque.me/resume/${balanceId}/invoice/${row.code}?internal`,
            '_blank',
          )
        }>
        <i className="far fa-file-pdf" />
      </button>
    );
  }

  typeIcon(cell, row) {
    return (
      <span>
        <i
          className={row.tipoInvoice === 'INVOICEEMPRESA' ? 'fas fa-building' : 'fas fa-truck'}
        />
      </span>
    );
  }

  services(cell, row) {
    return <div className="align-right">{row.atendimentos}</div>;
  }

  valueFiled(cell, row) {
    return (
      <div
        className="invoice-values"
        style={row.valor > 0 ? {color: '#4ab858'} : {color: 'red'}}>
        {parseFloat(row.valor).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}
      </div>
    );
  }

  cumulativeFiled(cell, row) {
    const getTextColor = !row.valorAcumulado
      ? 'black'
      : row.valorAcumulado > 0
      ? '#4ab858'
      : 'red';

    return (
      <div
        className="invoice-values"
        style={{
          color: getTextColor,
        }}>
        {row.valorAcumulado
          ? parseFloat(row.valorAcumulado).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          : 'N/A'}
      </div>
    );
  }

  doneButton(cell, row) {
    return (
      <button
        className={`btn btn-sm btn-default`}
        style={
          row.concluido
            ? {color: '#4ab858', textAlign: 'center'}
            : {color: '#c1c1c1', textAlign: 'center'}
        }
        onClick={() => {
          this.setState(
            {
              modal: 'close',
              closeId: this.props.invoices.filter((invoice) => invoice.codigo === row.code)[0]
                .lancamentoFinanceiro.codigo,
            },
            () => doOpenModal('CONTEXT_BASE'),
          );
        }}>
        {<i className="fas fa-check" />}
      </button>
    );
  }

  seenField(cell, row) {
    const {balanceId, invoices} = this.state;
    const {emailSentId} = this.props;
    let comp = '';
    let title = '';

    if (row.seen) {
      comp = 'fas fa-eye';
      title = row.seenDate;
    } else if (row.emailSent || emailSentId === row.code) {
      comp = 'fas fa-share-square';
      title = row.emailSentDate;
    } else comp = 'fas fa-envelope';

    /* const buttonList = [
      {
        label: "Enviar email",
        func: () => this.props.sendIndividualEmail(balanceId,row.code,row.nomeFantasia,row.email),
        icon: "fas fa-share-square"
      },
      {
        label: "Enviar por whatsapp",
        func: () => {
          if (!row.telefoneCelular) {
            return;
          };
          this.props.openWhatsApp(row.telefoneCelular, null);
        },
        icon: "fab fa-whatsapp"
      }
    ];

    const last = invoices[invoices.length - 1].code === row.code; */
    const last = true;

    return row.code ? (
      <div className={`btn-group show ${open}`}>
        {/*  <ReactTooltip /> */}
        <button
          data-toggle={`dropdown`}
          className={`btn btn-default dropdown-toggle hover-dropdown`}
          aria-expanded="false">
          <i className={comp} />
        </button>
        <ul
          className="dropdown-menu sideDropDown"
          style={{
            position: 'absolute',
            top: `${last ? '-5px' : '33px'}`,
            left: '-60px',
            ' willChange': 'top, left',
          }}>
          <li className="dropDown-li" title="Enviar Email">
            <a
              className="dropdown-item balance-dropDown-a"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.props.sendIndividualEmail(
                  balanceId,
                  row.code,
                  row.nomeFantasia,
                  row.email,
                );
              }}>
              <i className="fas fa-share-square" />
            </a>
          </li>

          <li className="dropDown-li" title="Enviar por whatsapp">
            <a
              className="dropdown-item balance-dropDown-a"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (!row.telefoneCelular) {
                  return;
                }
                this.props.openWhatsApp(row.telefoneCelular, null);
              }}>
              <i className="fab fa-whatsapp" />
            </a>
          </li>
        </ul>
      </div>
    ) : null;
  }

  componentWillReceiveProps(nextProps) {
    let {invoices, invoiceBilletLink, invoiceNotaLink} = nextProps;
    if (invoices) {
      this.setState({
        invoices: this.getData(invoices),
        filteredInvoices: this.getData(invoices),
        afterAction: false,
        afterActionApproved: false,
      });
    }
    if (invoiceBilletLink || invoiceNotaLink) {
      this.clearModal();
    }
  }

  clearModal() {
    this.setState({billetLink: [], invoiceLink: []});
  }

  filterInvoiceByManyFields = (input, row) => {
    const regex = new RegExp(input, 'ig');
    if (regex.test(row.code)) return true;
    if (regex.test(row.documento)) return true;
    return regex.test(row.nomeFantasia);
  };

  onSearchChange = (input) => {
    let {invoices, filteredInvoices} = this.state;
    if (input) {
      filteredInvoices = invoices.filter((row) => {
        return this.filterInvoiceByManyFields(input, row);
      });
      this.setState({
        filteredInvoices,
      });
    }
  };

  getData(invoices) {
    if (invoices) {
      return invoices.map((invoice) => ({
        type: invoice.base ? 'guincho' : 'empresa',
        tipoInvoice: invoice.tipoInvoice.codigo,
        code: invoice.codigo ? invoice.codigo : '',
        email: invoice.base ? invoice.base.email : invoice.empresa.email,
        telefoneCelular: invoice.base ? invoice.base.telefoneCelular : null,
        nomeFantasia: invoice.base ? invoice.base.nomeFantasia : invoice.empresa.nomeFantasia,
        razaoSocial: invoice.base ? invoice.base.razaoSocial : invoice.empresa.razaoSocial,
        documento: invoice.base ? invoice.base.documento : invoice.empresa.cnpj,
        valor: invoice.valor ? invoice.valor : '',
        acumulado: invoice.acumulado,
        valorAcumulado: invoice.valorAcumulado,
        atendimentos: invoice.atendimentos ? invoice.atendimentos : '',
        concluido: invoice.lancamentoFinanceiro.processada
          ? invoice.lancamentoFinanceiro.processada
          : '',
        billetLink: invoice.lancamentoFinanceiro.linkBoleto
          ? invoice.lancamentoFinanceiro.linkBoleto
          : null,
        nfLink: invoice.linkNotaFiscal ? invoice.linkNotaFiscal : null,
        seen: invoice.visualizada ? invoice.visualizada : null,
        emailSent: invoice.emailEnviado ? invoice.emailEnviado : null,
        emailSentDate: invoice.dataEmailEnviado ? invoice.dataEmailEnviado : null,
        seenDate: invoice.dataVisualizada ? invoice.dataVisualizada : null,
        usuarioNotaFiscalAprovada: invoice.usuarioNotaFiscalAprovada
          ? invoice.usuarioNotaFiscalAprovada
          : null,
        lancamentoId: invoice.lancamentoFinanceiro.codigo,
        valorNotaFiscalTomada: invoice.valorNotaFiscalTomada
          ? invoice.valorNotaFiscalTomada
          : null,
        linkNotaFiscalTomada: invoice.linkNotaFiscalTomada,
        notaFiscalAprovada: invoice.notaFiscalAprovada ? invoice.notaFiscalAprovada : null,
        valorNotaFiscal: invoice.valorNotaFiscal,
        dataRecebimentoNotaFiscalTomada: invoice.dataRecebimentoNotaFiscalTomada,
      }));
    } else return [];
  }
  clearSearch() {
    const {invoices} = this.state;
    this.setState({
      filteredInvoices: invoices,
    });
  }

  updateAllStatusNF() {
    MySwal.fire({
      title: 'Você tem certeza?',
      text: 'Deseja verificar as atualizações ?',
      icon: 'warning',
      width: '80',
      showCancelButton: true,
      confirmButtonText: 'Verificar',
      confirmButtonColor: '#25f4af',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        doGet(`general/updateAllStatusNF`)
          .then(() => {
            toastSuccess('Processado com sucesso');
          })
          .catch((error) => {
            toastError(
              error.mensagemCliente ||
                'Ops...Ocorreu um erro ao tentar verificar as atualizações.',
            );
          });
      }
    });
  }

  addLinkButton() {
    const {sendEmails, generateAllBillets} = this.props;
    return (
      <div className="toolbar-btnGroup">
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={() => {
            this.setState({modal: 'billet'});
            doOpenModal('CONTEXT_BASE');
          }}>
          <i className="fas fa-barcode" /> Adicionar link boletos
        </button>

        <button
          type="button"
          className="btn btn-danger btn-sm"
          onClick={() => generateAllBillets(this.state.balanceId)}>
          <i className="fas fa-barcode" /> Gerar boletos
        </button>

        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={() => {
            this.setState({
              modal: 'invoice',
              tipoNF: InvoiceConstants.NF_SERVICO,
            });
            doOpenModal('CONTEXT_BASE');
          }}>
          <i className="fas fa-file-invoice-dollar" /> Adicionar NF Serviço
        </button>

        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={() => {
            this.setState({
              modal: 'invoicePrestador',
              tipoNF: InvoiceConstants.NF_PRESTADOR,
            });
            doOpenModal('CONTEXT_BASE');
          }}>
          <i className="fas fa-file-invoice-dollar" /> Adicionar NF Prestador
        </button>

        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={() => sendEmails(this.state.balanceId)}>
          <i className="fas fa-paper-plane" /> Enviar email
        </button>
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={() => this.updateAllStatusNF()}>
          <i className="fas fa-sync" /> Verificar atualizações FocusNFe
        </button>
      </div>
    );
  }
  getBilletPartners(data) {
    const {setInvoiceBilletLink} = this.props;
    this.footer = null;
    if (data) {
      return data
        .sort((a, b) => a.nomeFantasia.localeCompare(b.nomeFantasia))
        .map((invoice, i) => {
          if (invoice.valor > 0 || invoice.valorAcumulado > 0)
            return (
              <div className="col-md-12" key={'row' + i} style={{padding: '3px'}}>
                <div className="col-md-1 form-group">
                  <span style={invoice.billetLink ? {color: '#4ab858'} : {color: 'inherit'}}>
                    <i
                      className={
                        invoice.tipoInvoice === 'INVOICEEMPRESA'
                          ? 'fas fa-building'
                          : 'fas fa-truck'
                      }
                    />
                  </span>
                </div>
                <div className="col-md-4 form-group">
                  <span style={{whiteSpace: 'nowrap'}} title={invoice.nomeFantasia}>
                    {invoice.nomeFantasia.length > 20
                      ? invoice.nomeFantasia.substr(0, 20) + '...'
                      : invoice.nomeFantasia}
                  </span>
                </div>
                <div className="col-md-7 form-group" style={{whiteSpace: 'nowrap'}}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={invoice.billetLink ? invoice.billetLink : ''}
                    value={this.state.billetLink[i] || ''}
                    onChange={(e) => this.handleBilletInput(e.target.value, i, invoice.code)}
                  />
                </div>
              </div>
            );
        });
    }
  }

  handleBilletInput(value, index, codeInvoice) {
    const {setInvoiceBilletLink} = this.props;
    let {billetLink} = this.state;
    billetLink[index] = value;
    this.setState({billetLink});
    setInvoiceBilletLink(value, this.state.balanceId, codeInvoice);
  }

  handleInvoiceInput(file, index, codeInvoice) {
    const {setInvoiceNfLink, setInvoiceNfLinkAdapter} = this.props;
    let {invoiceLink, tipoNF} = this.state;
    invoiceLink[index] = file;
    this.setState({invoiceLink});
    setInvoiceNfLinkAdapter(file, this.state.balanceId, codeInvoice, tipoNF);
  }

  handleBuildNfInvoice = (codeInvoice) => {
    buildInvoiceNF(codeInvoice)
      .then((res) => {
        if (res.data.sucesso) {
          toastSuccess(res.data.mensagemRetorno);
        } else {
          toastError(res.data.mensagemRetorno);
        }
        this.props.getInvoices(this.id);
      })
      .catch((err) => toastError(err.mensagemRetorno));
  };

  getInvoicePartners(data) {
    const {setInvoiceNfLink} = this.props;
    const {tipoNF} = this.state;

    const tipoInvoiceFilter = {
      servico: (invoice) => invoice.valorNotaFiscal > 0,
      prestador: (invoice) => invoice.valorNotaFiscalTomada > 0,
    };

    const hasNota = {
      servico: (invoice) => invoice.nfLink,
      prestador: (invoice) => invoice.linkNotaFiscalTomada,
    };

    this.footer = null;
    if (data) {
      return data
        .sort(
          (a, b) =>
            a.nomeFantasia.localeCompare(b.nomeFantasia) &&
            a.tipoInvoice.localeCompare(b.tipoInvoice),
        )
        .map((invoice, i) => {
          /* if (invoice.type === "empresa") */
          if (tipoInvoiceFilter[tipoNF](invoice))
            return (
              <div className="col-md-12" key={'row' + i} style={{padding: '3px'}}>
                <div className="col-md-1 form-group">
                  <span
                    style={hasNota[tipoNF](invoice) ? {color: '#4ab858'} : {color: 'inherit'}}>
                    <i
                      className={
                        invoice.tipoInvoice === 'INVOICEEMPRESA'
                          ? 'fas fa-building'
                          : 'fas fa-truck'
                      }
                    />
                  </span>
                </div>
                <div className="col-md-3 form-group">
                  <span style={{whiteSpace: 'nowrap'}} title={invoice.nomeFantasia}>
                    {invoice.nomeFantasia.length > 20
                      ? invoice.nomeFantasia.substr(0, 20) + '...'
                      : invoice.nomeFantasia}
                  </span>
                </div>
                <div className="col-md-6 form-group" style={{whiteSpace: 'nowrap'}}>
                  <input
                    type="file"
                    className="form-control"
                    // placeholder={invoice.nfLink ? invoice.nfLink : ''}
                    // value={this.state.invoiceLink[i] || ''}
                    onChange={(e) =>
                      this.handleInvoiceInput(
                        e.target.files[0],
                        this.state.balanceId,
                        invoice.code,
                      )
                    }
                  />
                </div>
                {this.state.tipoNF === InvoiceConstants.NF_SERVICO ? (
                  <div className="col-md-2">
                    <button
                      data-for="nf-build-invoice"
                      data-tip="Gerar nota fiscal"
                      onClick={() => this.handleBuildNfInvoice(invoice.code)}
                      className="btn btn-default">
                      <i className="fas fa-plus-square" />
                    </button>
                    <ReactTooltip id="nf-build-invoice" html multiline />
                  </div>
                ) : null}
              </div>
            );
        });
    }
  }

  getModalBody() {
    const {modal, invoices} = this.state;
    if (modal === 'billet') return this.getBilletPartners(invoices);
    else if (modal === 'invoice') return this.getInvoicePartners(invoices);
    else if (modal === 'invoicePrestador') return this.getInvoicePartners(invoices);
    else if (modal === 'close') return this.getClosesModal();
  }

  getTotal() {
    //(valor >= 0 ? valor - desconto : valor -( desconto*-1 )).toFixed(2).
    const {closeId, desconto, acrescimo} = this.state;
    const {invoices} = this.props;
    const closeInfo = invoices.filter(
      (invoice) => invoice.lancamentoFinanceiro.codigo === closeId,
    )[0];
    const {valor} = closeInfo;
    const {lancamentoFinanceiro} = closeInfo;
    const {processada} = lancamentoFinanceiro;

    if (processada) {
      return lancamentoFinanceiro.total.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      if (valor > 0) {
        return parseFloat(valor - desconto + acrescimo).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        return parseFloat(valor + (desconto - acrescimo)).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    }
  }
  doCopyText() {
    const {copyText} = this.state;
    this.copyArea.hidden = false;
    this.copyArea.select();
    document.execCommand('copy');
    this.copyArea.hidden = true;
    toastSuccess(copyText);
  }
  setCopyText(copyText) {
    this.setState({copyText}, () => this.doCopyText());
  }
  copyTotal() {
    const total = parseFloat(this.getTotal().replace('.', '').replace(',', '.'));
    if (total >= 0)
      this.setCopyText(
        total.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      );
    else {
      this.setCopyText(
        (total * -1).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      );
    }
  }
  getClosesModal() {
    const {closeId, closeDate, desconto, toPay, acrescimo} = this.state;
    const {invoices, closeInvoice} = this.props;
    const closeInfo = invoices.filter(
      (invoice) => invoice.lancamentoFinanceiro.codigo === closeId,
    )[0];
    const {
      valor,
      valorNotaFiscalTomada,
      linkNotaFiscalTomada,
      notaFiscalAprovada,
      codigo,
      usuarioNotaFiscalAprovada,
    } = closeInfo;
    const nomeAprovada = usuarioNotaFiscalAprovada ? usuarioNotaFiscalAprovada.nome : '';

    const {
      dataMovimento,
      dataVencimento,
      tipoMovimento,
      descricao,
      processada,
      descontos,
      acrescimos,
      dataProcessada,
    } = closeInfo.lancamentoFinanceiro;
    let bankName = '',
      bankNumber = '',
      agencyNumber = '',
      agencyCheckNumber = '',
      accountNumber = '',
      accountCheckNumber = '',
      holderFullname = '',
      holderTypeDocument = '',
      holderNumberDocument = '',
      chavePix = '';

    let baseOrCompanyDocument = '';
    let baseOrCompanyType = '';
    let pendenciaFiscal = '';

    if (closeInfo.empresa) {
      baseOrCompanyDocument = closeInfo.empresa.cnpj;
      baseOrCompanyType = 'CNPJ';
    } else if (closeInfo.base) {
      baseOrCompanyType = closeInfo.base.tipoDocumento;
      baseOrCompanyDocument = closeInfo.base.documento;
      pendenciaFiscal = closeInfo.base.pendenciaFiscal;
    }

    if (closeInfo.base && closeInfo.base.contaBancaria) {
      bankName = closeInfo.base.contaBancaria.bankName;
      bankNumber = closeInfo.base.contaBancaria.bankNumber;
      (agencyNumber = closeInfo.base.contaBancaria.agencyNumber),
        (agencyCheckNumber = closeInfo.base.contaBancaria.agencyCheckNumber);
      accountNumber = closeInfo.base.contaBancaria.accountNumber;
      accountCheckNumber = closeInfo.base.contaBancaria.accountCheckNumber;
      holderFullname = closeInfo.base.contaBancaria.holderFullname;
      holderTypeDocument = closeInfo.base.contaBancaria.holderTypeDocument;
      holderNumberDocument = closeInfo.base.contaBancaria.holderNumberDocument;
      chavePix = closeInfo.base.contaBancaria.chavePix;
    }

    const tipoBase = closeInfo.base ? 'base' : 'empresa';
    const baseName = closeInfo.base
      ? closeInfo.base.nomeFantasia
      : closeInfo.empresa.nomeFantasia;
    const notaFiscalPrestador = valorNotaFiscalTomada;
    const valorAcumulado = closeInfo.valorAcumulado;

    this.footer = (
      <div className="form-group">
        <button
          className="btn btn-primary"
          onClick={() =>
            closeInvoice(
              closeId,
              moment(closeDate).format('YYYY-MM-DD HH:mm:ss'),
              this.state.balanceId,
              this.state.desconto,
              this.state.acrescimo,
            )
          }
          type="submit">
          Dar Baixa
        </button>
      </div>
    );
    return (
      <div>
        <div className="col-md-8" style={{borderRight: '1px solid #e5e5e5'}}>
          <div className="form-group custom-tab">
            <label>Descricao</label>
            <br />
            <span>{descricao ? descricao : 'Sem descricao'}</span>
          </div>
          <div className="row">
            <div className="col-md-6 form-group custom-tab">
              <label>Base</label>
              <br />
              <span>{baseName ? baseName : 'Sem descricao'}</span>
            </div>
            <div className="col-md-6 form-group custom-tab">
              <label>CNPJ/CPF</label>
              <br />
              <span>
                {baseOrCompanyDocument && baseOrCompanyType === 'CNPJ' ? (
                  <MaskedInput
                    className="no-border copy-btn"
                    value={baseOrCompanyDocument}
                    mask="99.999.999/9999-99"
                  />
                ) : (
                  <MaskedInput
                    className="no-border copy-btn"
                    value={baseOrCompanyDocument}
                    mask="999.999.999-99"
                  />
                )}
              </span>
            </div>
          </div>
          <div className="row form-group">
            <div className="form-group custom-tab col-md-6">
              <label>Tipo</label>
              <span className="form-control">
                {tipoMovimento ? tipoMovimento.descricao : 'Sem tipo'}
              </span>
            </div>
            <div className="form-group custom-tab col-md-6">
              <label>Data Movimento</label>
              <span className="form-control">
                {dataMovimento
                  ? moment(dataMovimento, 'DD/MM/YY HH:mm').local().format('DD/MM/YYYY HH:mm')
                  : 'Sem data movimento'}
              </span>
            </div>
          </div>
          <div className="row form-group">
            <div className="form-group custom-tab col-md-6">
              <label>Data Vencimento</label>
              <span className="form-control">
                {dataVencimento
                  ? moment(dataVencimento, 'DD/MM/YY HH:mm').local().format('DD/MM/YYYY HH:mm')
                  : 'Sem data vencimento'}
              </span>
            </div>
            <div className="form-group custom-tab col-md-6">
              <label>Data da Baixa</label>
              <DateTime
                onChange={this.handleCloseDate}
                value={
                  processada
                    ? moment(dataProcessada, 'DD/MM/YY HH:mm')
                        .local()
                        .format('DD/MM/YYYY HH:mm')
                    : moment(closeDate, 'DD/MM/YY HH:mm').local().format('DD/MM/YYYY HH:mm')
                }
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="form-group custom-tab col-md-4">
              <label>Descontos / Taxas</label>
              <input
                type="number"
                value={desconto}
                disabled={processada}
                value={processada ? descontos : this.state.desconto}
                onChange={(e) => this.setState({desconto: parseFloat(e.target.value) || 0})}
                className="form-control"
              />
            </div>
            <div className="form-group custom-tab col-md-4">
              <label>Juros / Multa</label>
              <input
                type="number"
                value={acrescimo}
                disabled={processada}
                value={processada ? acrescimos : this.state.acrescimo}
                onChange={(e) => this.setState({acrescimo: parseFloat(e.target.value) || 0})}
                className="form-control"
              />
            </div>
            <div className="form-group custom-tab col-md-4">
              <div>
                <label>Nota Fiscal Prestador</label>
                <div style={{display: 'flex', alignItems: 'flex-end'}}>
                  <input
                    type="number"
                    disabled
                    value={notaFiscalPrestador || 0}
                    onChange={(e) =>
                      this.setState({
                        desconto: parseFloat(e.target.value) || 0,
                      })
                    }
                    className="form-control"
                  />
                  <button
                    disabled={!linkNotaFiscalTomada}
                    onClick={() => window.open(linkNotaFiscalTomada, '_blank')}
                    style={
                      this.state.afterActionApproved || notaFiscalAprovada
                        ? {color: '#4ab858'}
                        : {marginLeft: '10px'}
                    }
                    className="btn btn-default">
                    <i className="fas fa-file-invoice" />
                  </button>
                </div>
                {usuarioNotaFiscalAprovada && (
                  <p style={{marginTop: '3px'}}>Aprovada por: {nomeAprovada} </p>
                )}
              </div>
            </div>
            {tipoBase === 'base' &&
              !this.state.afterAction &&
              !notaFiscalAprovada &&
              linkNotaFiscalTomada && (
                <div
                  className="form-group custom-tab col-md-4"
                  style={{
                    float: 'right',
                    width: '100%',
                    maxWidth: '155px',
                    marginRight: '20px',
                    padding: 0,
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <button
                      style={{margin: '10px'}}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleApprove(codigo);
                      }}
                      className="btn btn-primary">
                      <i className="fas fa-check" />
                    </button>
                    <button
                      style={{margin: '10px'}}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleDesapprove(codigo);
                      }}
                      className="btn btn-danger">
                      <i className="fas fa-times" />
                    </button>
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group custom-tab col-md-6">
            <label>Valor</label>
            <br />
            <h2 style={{margin: '5px 0px -10px'}}>{`${valor.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}</h2>
          </div>
          <div className="form-group custom-tab col-md-6">
            <label>Total</label>
            <br />
            <h2
              className="copy-btn"
              onClick={() => this.copyTotal()}
              ref={(r) => (this.totalContainer = r)}
              style={{margin: '5px 0px -10px'}}>{`${this.getTotal()}`}</h2>
          </div>
          {valorAcumulado !== 0 && (
            <div className="form-group custom-tab col-md-12">
              <p className="text-danger">* Possui um valor acumulado.</p>
            </div>
          )}
          {pendenciaFiscal && (
            <div className="form-group custom-tab col-md-12">
              <p className="text-danger">* Possui Pendência Envio de NF</p>
            </div>
          )}

          {chavePix && (
            <div className="form-group custom-tab col-md-12">
              <label>Chave Pix</label>
              <br />
              <span>{chavePix}</span>
            </div>
          )}

          <div className="form-group custom-tab col-md-12">
            <label>Banco</label>
            <br />
            <span>{`${bankNumber} - ${bankName}`}</span>
          </div>

          <div className="form-group custom-tab col-md-6">
            <label>Agência</label>
            <br />
            <span className="copy-btn" onClick={() => this.setCopyText(agencyNumber)}>
              {`${agencyNumber} - ${agencyCheckNumber}`}
            </span>
          </div>
          <div className="form-group custom-tab col-md-6">
            <label>Nº Conta</label>
            <br />
            <span
              className="copy-btn"
              onClick={() => this.setCopyText(`${accountNumber}${accountCheckNumber}`)}>
              {`${accountNumber} - ${accountCheckNumber}`}
            </span>
          </div>
          <div className="form-group custom-tab col-md-12">
            <label>Nome Favorecido</label>
            <br />
            <span className="copy-btn" onClick={() => this.setCopyText(holderFullname)}>
              {holderFullname}
            </span>
          </div>

          <div
            className="form-group custom-tab col-md-12 copy-btn"
            onClick={() => this.setCopyText(holderNumberDocument)}>
            <label>CPF/ CNPJ Favorecido</label>
            <br />
            <div className="copy-btn" onClick={() => this.setCopyText(holderNumberDocument)}>
              {holderTypeDocument === 'CNPJ' ? (
                <MaskedInput
                  className="no-border copy-btn"
                  value={holderNumberDocument}
                  mask="99.999.999/9999-99"
                />
              ) : (
                <MaskedInput
                  className="no-border copy-btn"
                  value={holderNumberDocument}
                  mask="999.999.999-99"
                />
              )}
            </div>
          </div>
        </div>
        <input
          value={this.state.copyText}
          type="text"
          hidden
          ref={(copyArea) => (this.copyArea = copyArea)}
        />
      </div>
    );
  }

  handleCloseDate(closeDate) {
    this.setState({closeDate});
  }

  formatNomeFantasia = (row, cel) => {
    return `${cel.nomeFantasia} (${cel.razaoSocial})`;
  };

  render() {
    const {isInvoicesFetching, balanceResume} = this.props;
    const {filteredInvoices, modal, invoices} = this.state;
    //const partners = modal === 'billet' ? this.getBilletPartners(invoices) : this.getInvoicePartners(invoices)
    const modalBody = this.getModalBody();
    const table = (
      <ReactTable
        data={!isInvoicesFetching ? filteredInvoices : []}
        onSearchChange={this.onSearchChange}
        isFetching={isInvoicesFetching}
        clearSearch={this.clearSearch}
        customBtn={this.addLinkButton}>
        <TableHeaderColumn
          dataField={'type'}
          dataSort={true}
          dataFormat={this.typeIcon}
          width="50px"
        />
        <TableHeaderColumn dataFormat={this.formatNomeFantasia} width="260px" dataSort={true}>
          Nome Fantasia
        </TableHeaderColumn>
        <TableHeaderColumn dataField={'documento'} dataSort={true}>
          Documento
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField={'valor'}
          dataSort={true}
          width="80px"
          dataFormat={this.valueFiled}>
          Valor
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField={'valorAcumulado'}
          dataSort={true}
          width="100px"
          dataFormat={this.cumulativeFiled}>
          Acumulado
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField={'atendimentos'}
          dataSort={true}
          dataFormat={this.services}
          width="100px">
          Serviços
        </TableHeaderColumn>
        <TableHeaderColumn isKey={true} dataField={'code'}>
          Codigo
        </TableHeaderColumn>
        <TableHeaderColumn
          width="50px"
          dataFormat={this.doneButton}
          dataSort={true}
          dataField={'concluido'}
        />
        <TableHeaderColumn
          dataField="button"
          columnClassName="billet-class"
          width="50px"
          dataFormat={this.notaFiscalPrestadorButton}
        />
        <TableHeaderColumn dataField="button" dataFormat={this.invoiceButton} width="50px" />
        <TableHeaderColumn
          dataField="button"
          columnClassName="billet-class"
          dataFormat={this.billetButton}
          width="50px"
        />
        <TableHeaderColumn dataField="button" dataFormat={this.pdfButton} width="50px" />
        <TableHeaderColumn
          width="50px"
          columnClassName="billet-class"
          dataField={'seen'}
          dataSort={true}
          dataFormat={this.seenField}
        />
      </ReactTable>
    );

    const titleMap = {
      billet: 'Adicionar Link dos Boletos',
      close: 'Baixa Lançamento',
      invoice: 'Adicionar NF serviço',
      invoicePrestador: 'Adicionar NF prestador',
    };

    return (
      <div className="row">
        <CustomModalWithoutTabIndex
          title={titleMap[modal]}
          context={'CONTEXT_BASE'}
          customClass="big-modal"
          footer={this.footer}>
          {modalBody}
        </CustomModalWithoutTabIndex>
        <div className="col-md-12">
          <div className="wrapper wrapper-content">
            <div className="ibox">
              <div className="ibox-title">
                <Link to="/balance">
                  {' '}
                  <h5>Fechamento / </h5>
                </Link>
                <h5>{`${this.descricao} (${this.id})`}</h5>
                <div className="ibox-tools" />
              </div>
              <div className="ibox-content">
                <div className="cards-container">
                  <InvoiceCard
                    icon={'fas fa-truck-moving'}
                    value={balanceResume.atendimentos}
                    title={'Atendimentos'}
                    cClass={'navy-bg'}
                  />
                  <InvoiceCard
                    icon={'fas fa-plus'}
                    value={parseFloat(balanceResume.valorAReceber).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                    title={'A Receber (R$)'}
                    cClass={'green-bg'}
                  />
                  <InvoiceCard
                    icon={'fas fa-minus'}
                    value={parseFloat(balanceResume.valorAPagar * -1).toLocaleString(
                      undefined,
                      {minimumFractionDigits: 2},
                    )}
                    title={'A Pagar (R$)'}
                    cClass={'red-bg'}
                  />
                  <InvoiceCard
                    icon={'fas fa-equals'}
                    value={parseFloat(balanceResume.resultado).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                    title={'Resultado (R$)'}
                    cClass={balanceResume.resultado >= 0 ? 'green-bg' : 'red-bg'}
                  />
                </div>
                {table}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  invoices: state.balances.invoices,
  isInvoicesFetching: state.balances.isInvoicesFetching,
  balanceResume: state.balances.balanceResume,
  emailSentId: state.balances.emailSentId,
  invoiceBilletLink: state.balances.invoiceBilletLink,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInvoices,
      setInvoiceBilletLink,
      sendEmails,
      setInvoiceNfLink,
      setInvoiceNfLinkAdapter,
      sendIndividualEmail,
      closeInvoice,
      billetButtonActions,
      sendNewBillet,
      generateAllBillets,
      dismissNotaFiscal,
      openWhatsApp,
      clearInvoices,
      clearBalanceResume,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
